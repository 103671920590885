var $alslogo_schweif_mask = $('#als-logo #schweif-mask-rect');
var $als_wrapper = $('#als-wrapper');
var $alslogo_text = $('#als-logo .als-logo-text');
var $page_top = $('.page-top');
var $page_bottom = $('.page-bottom');

// Init animation
$(function() {

    $als_wrapper.animate({
        opacity: 1,
    }, 2000, 'swing', function() {
        $alslogo_text
            .delay(1000)
            .addClass('animate');
        setTimeout(initPage, 3000);
    });

    animateMask();

});

function animateMask() {
    $alslogo_schweif_mask.delay(200).animate({
        height: "100%",
        opacity: 1,
    }, 1000, 'easeInSine', function() {
        animateText();
    });
}

function animateText() {
    $alslogo_text.delay(1000).animate({
        transform: "translate(0, 0)",
        opacity: 1,
    }, 1200, 'easeInSine', function() {
    });
}

// Init page
function initPage() {
    $page_top.addClass('initPage');
    $page_bottom.addClass('initPage');
    $('body').addClass('initPage');
}

// Activate column (zoom)
$(document).on( "click", ".company-column:not(.active)", function() {
    $(this).parent().addClass('active-child');
    $(this).parent().find('.active').removeClass('active');
    $(this).addClass('active');
});

// Back to overview
$(document).on( "click", ".page-top.initPage #als-logo, .page-bottom a.back-to-overview", function(e) {
    e.preventDefault();
    var $activeRow = $('.page-bottom.initPage .company-row.active-child');
    $activeRow.removeClass('active-child');
    $activeRow.find('.active').removeClass('active');
});

// Go to page 1
$(document).on( "click", "a.go-to-page-1", function(e) {
    e.preventDefault();
    $('.page-bottom.initPage .company-row.active-child').find('.active').removeClass('active');
    $('.page-bottom.initPage .company-row .company-column.column-alfa').addClass('active');
});
// Go to page 2
$(document).on( "click", "a.go-to-page-2", function(e) {
    e.preventDefault();
    $('.page-bottom.initPage .company-row.active-child').find('.active').removeClass('active');
    $('.page-bottom.initPage .company-row .company-column.column-burmeister').addClass('active');
});
// Go to page 3
$(document).on( "click", "a.go-to-page-3", function(e) {
    e.preventDefault();
    $('.page-bottom.initPage .company-row.active-child').find('.active').removeClass('active');
    $('.page-bottom.initPage .company-row .company-column.column-sms').addClass('active');
});